<style lang="scss">
.tiponcard {
  .tabelle-box {
    .head-row {
      [class*='col-'] {
        text-transform: none !important;
      }
    }
    .col-12 {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  .single-row {
    margin-bottom: 20px;

    img {
      max-height: 300px;
      display: block;
      margin: 20px auto;
    }
  }
  h3 {
    margin-top: 0;
  }
  .tab-group-container {
    margin: 50px 0;
    .title-container {
      .title {
        &:hover {
          background: #eee;
        }
      }
      .active {
      }
    }
  }
}
</style>

<template>
  <div class="tiponcard content container">
    <h1>Tip on Card</h1>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/tiponcard.jpg" alt="Tip on Card" class="img-fluid" />
    </div>
    <ul class="content-ul">
      <li><strong>Top platziert:</strong> Ihre Werbebotschaft ist auf der Titelseite oder der U4 ein Garant für Auffälligkeit.</li>
      <li><strong>Werbung zum Angreifen:</strong> Einfach abziehen und mitnehmen, z.B. als Gutschein.</li>
      <li><strong>Star des Tages:</strong> An Ihrem gewünschten Erscheinungstag gibt es diese Werbeform nur einmal.</li>
    </ul>

    <div class="whitespace"></div>
    <h2>Ihre Werbemöglichkeiten</h2>
    <TabGroup>
      <Tab tabid="toc-tab1" :title="'Tip on Card'" active>
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/tiponcard.png" alt="Mantel" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="single-row">
              <h3>Tip on Card</h3>
              <p><strong>Format</strong><br />105 x 148 mm (+3 mm Beschnitt)</p>
              <p><strong>Papier</strong><br />LWC, 170 g/m² matt mit Volumen</p>
              <p><strong>Platzierung</strong><br />Titelseite bzw. U4</p>
              <p><strong>Verarbeitung</strong><br />Tip on Cards werden maschinell ausschließlich in Hochformat verarbeitet.</p>
              <p><strong>Mindestmengen:</strong></p>
              <ul class="content-ul">
                <li>Titelseite: 50.000 Stück (im jeweiligen Bundesland; Addition nicht möglich)</li>
                <li>U4: 5.000 Stück</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Die Kosten für die Tip On Card setzen sich aus den Druck- und Verarbeitungskosten zusammen.</div>
      </Tab>
      <Tab tabid="toc-tab2" :title="'Tip on Card perforiert'">
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/tiponcard-perforiert.png" alt="Mantel" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="single-row">
              <h3>Tip on Card perforiert</h3>
              <p><strong>Format</strong><br />105 x 148 mm (+3 mm Beschnitt)</p>
              <p><strong>Papier</strong><br />BD, 200g/m² matt od. glänzend</p>
              <p><strong>Platzierung</strong><br />Titelseite bzw. U4</p>
              <p><strong>Verarbeitung</strong><br />Tip on Cards werden maschinell ausschließlich in Hochformat verarbeitet.</p>
              <p><strong>Mindestmengen:</strong></p>
              <ul class="content-ul">
                <li>Titelseite: mindestens 50.000 Stück (im jeweiligen Bundesland, Addition nicht möglich)</li>
                <li>U4: mindestens 5.000 Stück</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tabelle-box tabelle-width-50">
          <div class="headline">
            Perforationskosten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">Pauschalkosten</div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Pauschalkosten</div>
            <div class="col-lg-6 col-6 text-align-left text-right-small">1-fach Perforation</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">400,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Pauschalkosten</div>
            <div class="col-lg-6 col-6 text-align-left text-right-small">2-fach Perforation</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">500,00</div>
          </div>
        </div>
        <div class="info-txt-preis">Die Kosten für die Tip On Card setzen sich aus den Druck- und Verarbeitungskosten und Perforationskosten (optional) zusammen.</div>
      </Tab>
    </TabGroup>

    <div class="row">
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">unter 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">Titelseite</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">166,32</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">173,46</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">U4</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">130,68</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">136,29</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">über 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">Titelseite</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">163,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">170,94</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">U4</div>
            <div class="col-6 text-align-left visible-small">Mo-Sa</div>
            <div class="col-lg-4 col-6 text-align-right">128,70</div>
            <div class="col-6 text-align-left visible-small">So</div>
            <div class="col-lg-4 col-6 text-align-right">134,31</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tabelle-box tabelle-width-50">
      <div class="headline">
        Druckkosten
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-4 text-align-left">Stück</div>
        <div class="col-lg-4 text-align-right">Preis</div>
        <div class="col-lg-4 text-align-right">+1.000 Stk.</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">5.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">231,40</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">9,10</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">10.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">276,90</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">9,10</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">20.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">369,20</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">9,10</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">30.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">461,50</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">9,10</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">40.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">552,50</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">9,10</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">50.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">640,90</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">8,97</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">100.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">1.041,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">5,72</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">200.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">1.575,60</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">5,72</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">300.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">2.250,30</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">5,72</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">400.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">2.822,30</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">5,72</div>
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich per 1000 Stück in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer.</div>
    <div class="info-box-spezifikationen"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen finden Sie hier.</router-link></div>
    <div class="btn-rechner">
      <div class="head-txt">Jetzt den Preis Ihrer Beilage sofort berechnen.</div>
      <div class="btn-main">
        <router-link :to="{ name: 'calculator' }"
          ><i class="material-icons">calculate</i><span>Druckkosten-<strong>Rechner</strong></span></router-link
        >
      </div>
    </div>

    <div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/beilagen/Tip-on-Card.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarife Tip on Card
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>303 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Tiponcard',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
